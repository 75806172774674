import { QrCode } from "@mui/icons-material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid-pro";
import { uniq } from "lodash";
import moment from "moment";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { hasRole, LayoutContext } from "wcz-layout";
import { AutocompleteEditInputCell, editInputCellValidation, GridDeleteCellItem, GridToolbar, TableContainer } from "wcz-x-data-grid";
import GenerateQr from "../components/locations/GenerateQr";
import Location, { LocationOptions } from '../models/Location';
import { useCreateLocation, useDeleteLocation, useGetLocations, useUpdateLocation } from "../services/LocationService";
import { adminRole } from "../utils/Roles";

export default function Locations() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const [options, setOptions] = useState<LocationOptions>({} as LocationOptions);
    const [generateQrLocationId, setGenerateQrLocationId] = useState<string>("");

    const { data: locations = [], isLoading } = useGetLocations({
        onSuccess: data => {
            setOptions({
                buildings: uniq(data.map(d => d.building)).filter(item => !!item),
                floors: uniq(data.map(d => d.floor)).filter(item => !!item)
            });
        }
    });

    useEffect(() => changeTitle(t("Locations")), [i18n.language]);

    const create = useCreateLocation();
    const update = useUpdateLocation();
    const remove = useDeleteLocation();

    const columns: GridColDef[] = [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: params => [
                <GridActionsCellItem key="qr" label="QR code" icon={<QrCode />} onClick={() => setGenerateQrLocationId(String(params.id))} showInMenu />,
                <GridDeleteCellItem key="delete" id={params.id} remove={remove} showInMenu />
            ],
        },
        {
            field: 'name', headerName: t("Name"), width: 300, editable: hasRole(adminRole), preProcessEditCellProps: editInputCellValidation.hasLength,
            renderCell: ({ value, id }) => <Link to={`/ratings/${id}`} >{value}</Link>
        },
        { field: 'building', headerName: t("Building"), width: 150, editable: hasRole(adminRole), renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.buildings} freeSolo /> },
        { field: 'floor', headerName: t("Floor"), width: 150, editable: hasRole(adminRole), renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.floors} freeSolo /> },
        { field: 'averageRating', headerName: t("AverageRating"), width: 220, type: 'number' },
        { field: 'lastRating', headerName: t("LastRating"), width: 220, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'ratingsCount', headerName: t("NumberOfRatings"), width: 220, type: 'number' }
    ]

    const processRowUpdate = async (row: GridRowModel<Location>): Promise<Location> => {
        if (row.isNew)
            await create.mutateAsync(row);
        else {
            await update.mutateAsync(row);
        }

        return { ...row, isNew: false };
    };

    return (
        <Fragment>
            <TableContainer>
                <DataGridPro rows={locations} columns={columns} components={{ Toolbar: GridToolbar }} loading={isLoading}
                    editMode="row" processRowUpdate={processRowUpdate} columnVisibilityModel={{ id: hasRole(adminRole) }} />
            </TableContainer>

            <GenerateQr locationId={generateQrLocationId} setLocationId={setGenerateQrLocationId} />
        </Fragment>
    );
}