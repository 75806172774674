import { Home, LocationOn } from '@mui/icons-material';
import { purple, teal } from '@mui/material/colors';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FileProvider } from 'wcz-file';
import { LayoutProvider, LeftDrawerItem } from 'wcz-layout';
import packageJson from '../package.json';
import AddRating from './pages/AddRating';
import HomePage from './pages/HomePage';
import Locations from './pages/Locations';
import Ratings from './pages/Ratings';
import { fileUrl } from './utils/BaseUrl';

const leftDrawerItems: LeftDrawerItem[] = [
    { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
    { title: "Locations", path: "/locations", icon: <LocationOn fontSize="large" /> }
];

export default function App() {

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={teal[700]} secondaryColor={purple[500]} leftDrawerItems={leftDrawerItems}>
                <FileProvider baseUrl={fileUrl}>
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/locations' element={<Locations />} />
                        <Route path='/ratings/:locationId' element={<Ratings />} />
                        <Route path='/rating/create/:locationId' element={<AddRating />} />
                    </Routes>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
}